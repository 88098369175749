  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency Code*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.code" v-validate="'required|min:3|max:3'" name="Currency Code" :disabled="currencyId > 0" />
          <span class="text-danger text-sm">{{ errors.first('Currency Code') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:25'" name="Currency Name" />
          <span class="text-danger text-sm">{{ errors.first('Currency Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Smallest Denominatinon*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" type="number" min="0" v-model.number="form.smallestDenomination" v-validate="'required|decimal'" name="Smallest Denominatinon" />
          <span class="text-danger text-sm">{{ errors.first('Smallest Denominatinon') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Available Stock</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" type="number" min="0" step="1" v-model.number="form.availableStock" v-validate="'min_value:0'" name="Available Stock" />
          <span class="text-danger text-sm">{{ errors.first('Available Stock') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Min. Amount For Boost</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" type="number" min="0" v-model.number="form.minAmountForBoost" v-validate="'decimal|min_value:0'" name="Minimum Amount For Boost" />
          <span class="text-danger text-sm">{{ errors.first('Minimum Amount For Boost') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch class="" v-model="form.isActive" />
        </div>
      </div>
      
      <div v-if="currencyId" class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>API Rate</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" type="number" min="0" v-model.number="form.apiRate" disabled name="API Rate" />
        </div>
      </div>

      <div v-if="currencyId" class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>API Rate Last Updated</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.apiRateLastUpdatedDate" disabled name="API Rate Last Updated" />
        </div>
      </div>

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
import { format } from 'date-fns'

export default {
  components: {
  },
  props: {
    applicationId: { required: true },
    currencyId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        code: '',
        name: '',
        smallestDenomination: null,
        availableStock: null,
        minAmountForBoost: null,
        isActive: true,
        apiRate: null,
        apiRateLastUpdatedDate: null,
      }),

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.currencyId == 0 ? 'Add Currency' : 'Edit Currency'
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('currencyexchange')) {
      this.$router.push('/error-404')
    }
    
    try {
      if (this.currencyId) {
        this.$vs.loading()
        const currency = await this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/currencies/${this.currencyId}`)
        const apiRateLastUpdatedDateLocalTime = currency.data.apiRateLastUpdatedDate && format(new Date(currency.data.apiRateLastUpdatedDate), this.activeUserInfo.dateTimeFormat)
        this.form = new Form({...currency.data, apiRateLastUpdatedDate: apiRateLastUpdatedDateLocalTime })
      }
      
    } catch (error) {
      this.$_notifyFailure(error)
    } 
    this.$vs.loading.close();


  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      if (!this.currencyId) {
        try {
          await this.$http.post(`api/currencyExchanges/apps/${this.applicationId}/currencies`, this.form.data())
          this.$_notifySuccess('Successfully added branch');
          this.onCancel()
        } catch (error) {
          this.$_notifyFailureByResponseData(error.response)
        } finally {
          this.$vs.loading.close()
        }
      } else {
        try {
          await this.$http.put(`api/currencyExchanges/apps/${this.applicationId}/currencies`, this.form.data())
          this.$_notifySuccess('Successfully updated branch');
          this.onCancel()
        } catch (error) {
          this.$_notifyFailureByResponseData(error.response)
        } finally {
          this.$vs.loading.close()
        }
      }
      
    },
    onCancel () {
      this.$router.push({name: 'application-currency-exchange-currencies', 
        params: { applicationId: this.applicationId }
      })
    },
  }
}
</script>

<style>

</style>
